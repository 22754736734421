/*
Flaticon icon font: Flaticon
*/
@font-face {
	font-family: "Flaticon";
	src: url("../../assets/fonts/Flaticon.eot");
	src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
	url("../../assets/fonts/Flaticon.woff2") format("woff2"),
	url("../../assets/fonts/Flaticon.woff") format("woff"),
	url("../../assets/fonts/Flaticon.ttf") format("truetype"),
	url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	font-weight: normal;
	font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: "Flaticon";
		src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
	}
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
	font-family: Flaticon;
	font-style: normal;
}

.flaticon-enter:before { content: "\f100"; }
.flaticon-verify:before { content: "\f101"; }
.flaticon-file:before { content: "\f102"; }
.flaticon-comment:before { content: "\f103"; }
.flaticon-customer:before { content: "\f104"; }
.flaticon-mortarboard:before { content: "\f105"; }
.flaticon-upload:before { content: "\f106"; }
.flaticon-hiring:before { content: "\f107"; }
.flaticon-candidate:before { content: "\f108"; }
.flaticon-working-at-home:before { content: "\f109"; }
.flaticon-choice:before { content: "\f10a"; }
.flaticon-appointment:before { content: "\f10b"; }
.flaticon-right-arrow-of-straight-thin-line:before { content: "\f10c"; }
.flaticon-right-arrow:before { content: "\f10d"; }
.flaticon-left-quote:before { content: "\f10e"; }
.flaticon-apple:before { content: "\f10f"; }
.flaticon-send:before { content: "\f110"; }
.flaticon-left-arrow:before { content: "\f111"; }
.flaticon-accounting:before { content: "\f112"; }
.flaticon-businessman-paper-of-the-application-for-a-job:before { content: "\f113"; }
.flaticon-placeholder:before { content: "\f114"; }
.flaticon-resume:before { content: "\f115"; }
.flaticon-pencil:before { content: "\f116"; }